import React, {useState} from 'react';
import {Data, AssignmentsData} from '../types';
import axios from 'axios';
import Assignments from "./Assigments";

const REST_ASSIGN_URL = 'http://localhost:8080/api/assign';
// const REST_ASSIGN_URL = '/api/assign';

const fileNames: string[] = [
    "/data/5Techs30Tasks25Locations.json",
    "/data/3Techs30Tasks3Locations.json",
    "/data/WeidnerNthDakotaFirst40.json",
    "/data/mini.json"
];

// List of skills sourced from https://docs.google.com/spreadsheets/d/1K0GFXTdI3jEuMpbdKwq-iugjIpTt6oNKa3Z04YqMjps#gid=696092705
const skills: string[] = [
    "Appliance Repair",
    "Carpentry",
    "Cleaning",
    "Ceramic Tile",
    "Counters",
    "Doors",
    "Drywall",
    "Electrical",
    "Flooring",
    "Grounds",
    "HVAC",
    "Locksmithing",
    "Paint",
    "Pest Control",
    "Plumbing",
    "Safety",
    "Stucco",
    "Windows"
]

const initialData: Data = {
        "name": "Empty Data",
        "configuration": {
            "startDate": "2024-06-05",
            "runTimeSeconds": 5,
            "bufferBetweenWorkOrdersMinutes": 5,
            "defaultWorkOrderDurationMinutes": 55,
            "constrainTechnicianWithinWorkingHours": true,
            "constrainUrgentJobsFirst": true,
            "constrainResidentRaisedJobsNext": true,
            "constrainDateRaisedOrder": true,
            "constrainTechnicianCertified": true,
            "constrainTechnicianLocationStability": true,
            "constrainTechnicianSkillMatch": true,
            "constrainPropertyAccess": true
        },
        "technicians": [],
        "workOrders": []
    }
;

const formatDateForInput = (dateString : string|undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const WoaEditor: React.FC = () => {
    const [fileName, setFileName] = useState<string>(fileNames[0]);
    const [data, setData] = useState<Data>(initialData);
    const [assignmentsData, setAssignmentsData] = useState<AssignmentsData>();
    const [loading, setLoading] = useState(false);
    const [nextId, setNextId] = useState(1);

    const handleLoadStaticData = async () => {
        setLoading(true);
        setAssignmentsData(undefined);
        try {
            const response = await axios.get(fileName);
            setData(response.data);
        } catch (error) {
            console.error('Error loading static data', error);
            alert('Failed to load static data');
        } finally {
            setLoading(false);
        }
    };

    const generateId = () : string => {
        setNextId(nextId + 1);
        return String(nextId);
    }
    const handleConfigurationChange = (field: string, value: any) => {
        setData({...data, configuration: {...data.configuration, [field]: value}});
    };

    const handleTechnicianChange = (index: number, field: string, value: any) => {
        const updatedTechnicians = [...data.technicians];
        updatedTechnicians[index] = {...updatedTechnicians[index], [field]: value};
        setData({...data, technicians: updatedTechnicians});
    };

    const handleTechnicianSkillChange = (techIndex: number, skillIndex: number, field: string, value: any) => {
        const updatedTechnicians = [...data.technicians];
        const updatedSkills = [...updatedTechnicians[techIndex].technicianSkills];
        updatedSkills[skillIndex] = {...updatedSkills[skillIndex], [field]: value};
        updatedTechnicians[techIndex].technicianSkills = updatedSkills;
        setData({...data, technicians: updatedTechnicians});
    };

    const handlePropertiesChange = (techIndex: number, propertyIndex: number, field: string, value: any) => {
        const updatedTechnicians = [...data.technicians];
        const updatedProperties = [...updatedTechnicians[techIndex].properties || []];
        updatedProperties[propertyIndex] = {...updatedProperties[propertyIndex], [field]: value};
        updatedTechnicians[techIndex].properties = updatedProperties;
        setData({...data, technicians: updatedTechnicians});
    }

    const handleWorkOrderChange = (index: number, field: string, value: any) => {
        const updatedWorkOrders = [...data.workOrders];
        updatedWorkOrders[index] = {...updatedWorkOrders[index], [field]: value};
        setData({...data, workOrders: updatedWorkOrders});
    };

    const handleRequiredSkillChange = (orderIndex: number, skillIndex: number, field: string, value: any) => {
        const updatedWorkOrders = [...data.workOrders];
        const updatedSkills = [...updatedWorkOrders[orderIndex].requiredSkills];
        updatedSkills[skillIndex] = {...updatedSkills[skillIndex], [field]: value};
        updatedWorkOrders[orderIndex].requiredSkills = updatedSkills;
        setData({...data, workOrders: updatedWorkOrders});
    };

    const addTechnician = () => {
        const id = generateId()
         setData({
            ...data,
            technicians: [
                ...data.technicians,
                {id: id, name: "Tech " + id, technicianSkills: []}
            ]
        });
    };

    const deleteTechnician = (index: number) => {
        const updatedTechnicians = data.technicians.filter((_, i) => i !== index);
        setData({...data, technicians: updatedTechnicians});
    };

    const addTechnicianSkill = (techIndex: number) => {
        const updatedTechnicians = [...data.technicians];
        updatedTechnicians[techIndex].technicianSkills = [
            ...updatedTechnicians[techIndex].technicianSkills,
            {skill: {name: "Appliance Repair"}, years: 0, timesUsed: 0, certified: false}
        ];
        setData({...data, technicians: updatedTechnicians});
    };

    const deleteTechnicianSkill = (techIndex: number, skillIndex: number) => {
        const updatedTechnicians = [...data.technicians];
        updatedTechnicians[techIndex].technicianSkills = updatedTechnicians[techIndex].technicianSkills.filter((_, i) => i !== skillIndex);
        setData({...data, technicians: updatedTechnicians});
    };

    const addProperty = (techIndex: number) => {
        const updatedTechnicians = [...data.technicians];
        updatedTechnicians[techIndex].properties = [
            ...updatedTechnicians[techIndex].properties || [],
            {id: "Property 1"}
        ];
        setData({...data, technicians: updatedTechnicians});
    };

    const deleteProperty = (techIndex: number, propertyIndex: number) => {
        const updatedTechnicians = [...data.technicians];
        updatedTechnicians[techIndex].properties = updatedTechnicians[techIndex].properties?.filter((_, i) => i !== propertyIndex);
        setData({...data, technicians: updatedTechnicians});
    }

    const addWorkOrder = () => {
        const id = generateId()
        setData({
            ...data,
            workOrders: [
                ...data.workOrders,
                {id: id, name: "Work Order " + id, requiredSkills: [], location: {id: "Unit 1", property: {id: "Property 1"}}}
            ]
        });
    };

    const deleteWorkOrder = (index: number) => {
        const updatedWorkOrders = data.workOrders.filter((_, i) => i !== index);
        setData({...data, workOrders: updatedWorkOrders});
    };

    const addRequiredSkill = (orderIndex: number) => {
        const updatedWorkOrders = [...data.workOrders];
        updatedWorkOrders[orderIndex].requiredSkills = [
            ...updatedWorkOrders[orderIndex].requiredSkills,
            {skill: {name: "Appliance Repair"}, requiresCertification: false}
        ];
        setData({...data, workOrders: updatedWorkOrders});
    };

    const deleteRequiredSkill = (orderIndex: number, skillIndex: number) => {
        const updatedWorkOrders = [...data.workOrders];
        updatedWorkOrders[orderIndex].requiredSkills = updatedWorkOrders[orderIndex].requiredSkills.filter((_, i) => i !== skillIndex);
        setData({...data, workOrders: updatedWorkOrders});
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(REST_ASSIGN_URL, data);
            setAssignmentsData(response.data);
        } catch (error) {
            console.error('Error submitting data', error);
            alert('Assignment server failed');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <h1>Task Assignment - Test UI</h1>
            {assignmentsData &&
                <>
                    <hr/>
                    <Assignments data={assignmentsData}/>
                    <hr/>
                </>
            }
            <div>
                <h1>Input Data</h1>
                <ul>
                    <li>Start by entering some Input Data:</li>
                    <li>... You can load some starting data from one of the files, OR</li>
                    <li>... Enter your own data in the fields below (OR do both).</li>
                    <li>Then click <strong>Magic Assign</strong>. Results will display at the top (after the given number of Run Time Seconds).</li>
                </ul>
            </div>
            <div className="loadData">
                <h2>Load Data</h2>
                <select value={fileName} onChange={(e) => setFileName(e.target.value)}>
                {fileNames.map((name, nameIndex) => (
                    <option key={nameIndex} value={name}>{name}</option>
                ))}
                </select>
                <button onClick={handleLoadStaticData}>Load Data</button>
                {data.name && <p>Loaded: {data.name}</p>}
            </div>
            <div>
                <h2>Configuration</h2>
                <p>Configuration options that drive how the algorithm works.</p>
                <table className="configuration">
                    <tbody>
                    <tr>
                        <th>Start Date:</th>
                        <td>
                            <input
                                type="date"
                                value={data.configuration.startDate}
                                onChange={(e) => handleConfigurationChange("startDate", e.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Run Time Seconds:</th>
                        <td>
                            <input
                                type="number"
                                value={data.configuration.runTimeSeconds}
                                onChange={(e) => handleConfigurationChange("runTimeSeconds", parseInt(e.target.value))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Default Work Order Duration Minutes:</th>
                        <td>
                            <input
                                type="number"
                                value={data.configuration.defaultWorkOrderDurationMinutes}
                                onChange={(e) => handleConfigurationChange("defaultWorkOrderDurationMinutes", parseInt(e.target.value))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Buffer Between Work Orders Minutes:</th>
                        <td>
                            <input
                                type="number"
                                value={data.configuration.bufferBetweenWorkOrdersMinutes}
                                onChange={(e) => handleConfigurationChange("bufferBetweenWorkOrdersMinutes", parseInt(e.target.value))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Technician Location Stability:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainTechnicianLocationStability || false}
                                onChange={(e) => handleConfigurationChange("constrainTechnicianLocationStability", e.target.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Property Access:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainPropertyAccess || false}
                                onChange={(e) => handleConfigurationChange("constrainPropertyAccess", e.target.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Technician Skill Match:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainTechnicianSkillMatch || false}
                                onChange={(e) => handleConfigurationChange("constrainTechnicianSkillMatch", e.target.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Technician Certified:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainTechnicianCertified || false}
                                onChange={(e) => handleConfigurationChange("constrainTechnicianCertified", e.target.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Technician Within Working Hours:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainTechnicianWithinWorkingHours || false}
                                onChange={(e) => handleConfigurationChange("constrainTechnicianWithinWorkingHours", e.target.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Urgent Jobs First:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainUrgentJobsFirst || false}
                                onChange={(e) => handleConfigurationChange("constrainUrgentJobsFirst", e.target.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Resident Raised Jobs Next:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainResidentRaisedJobsNext || false}
                                onChange={(e) => handleConfigurationChange("constrainResidentRaisedJobsNext", e.target.checked)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Constrain Date Raised Order:</th>
                        <td>
                            <input
                                type="checkbox"
                                checked={data.configuration.constrainDateRaisedOrder || false}
                                onChange={(e) => handleConfigurationChange("constrainDateRaisedOrder", e.target.checked)}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <button className="smartAssign" onClick={handleSubmit}>Magic Assign</button>
            <div>
                <h2>Technicians</h2>
                <p>The Maintenance Technicians to whom the work will be assigned.</p>
                {data.technicians.map((tech, techIndex) => (
                    <div className="technician" key={techIndex}>
                        <label>ID: </label>
                        <input
                            type="text"
                            value={tech.id}
                            onChange={(e) => handleTechnicianChange(techIndex, "id", e.target.value)}
                        />
                        <label>Name: </label>
                        <input
                            type="text"
                            value={tech.name}
                            onChange={(e) => handleTechnicianChange(techIndex, "name", e.target.value)}
                        />
                        <table>
                            <thead>
                            <tr>
                                <th>Has Skill</th>
                                <th>Years</th>
                                <th>Times Used</th>
                                <th>Certified</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tech.technicianSkills.map((skill, skillIndex) => (
                                <tr key={skillIndex}>
                                    <td>
                                        <select
                                            onChange={(e) => handleTechnicianSkillChange(techIndex, skillIndex, "skill", {name: e.target.value})}>
                                            {skills.map((s) => (
                                                <option key={s} value={s} selected={s === skill.skill.name}>{s}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={skill.years}
                                            onChange={(e) => handleTechnicianSkillChange(techIndex, skillIndex, "years", parseInt(e.target.value))}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            value={skill.timesUsed}
                                            onChange={(e) => handleTechnicianSkillChange(techIndex, skillIndex, "timesUsed", parseInt(e.target.value))}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={skill.certified}
                                            onChange={(e) => handleTechnicianSkillChange(techIndex, skillIndex, "certified", e.target.checked)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => deleteTechnicianSkill(techIndex, skillIndex)}>Delete
                                            Skill
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <table>
                            <thead>
                            <tr>
                                <th>Has Access to Property ID</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tech.properties && tech.properties.map((property, propertyIndex) => (
                                <tr key={propertyIndex}>
                                    <td>
                                        <input
                                            type="text"
                                            value={property.id}
                                            onChange={(e) => handlePropertiesChange(techIndex, propertyIndex, "id", e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => deleteProperty(techIndex, propertyIndex)}>Delete Property Access</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <button onClick={() => addTechnicianSkill(techIndex)}>Add Skill</button>
                        <button onClick={() => addProperty(techIndex)}>Add Property Access</button>
                        <button onClick={() => deleteTechnician(techIndex)}>Delete Technician</button>
                    </div>
                ))}
                <button onClick={addTechnician}>Add Technician</button>
            </div>
            <button className="smartAssign" onClick={handleSubmit}>Magic Assign</button>
            <div>
                <h2>Work Orders</h2>
                <p>The work orders that need to be assigned to the Maintenance Technicians.</p>
                {data.workOrders.map((order, orderIndex) => (
                    <div className="workOrder" key={orderIndex}>
                        <label>ID: </label>
                        <input
                            type="text"
                            value={order.id}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "id", e.target.value)}
                        />
                        <label>Name: </label>
                        <input
                            type="text"
                            value={order.name}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "name", e.target.value)}
                        />
                        <label>Location ID: </label>
                        <input
                            type="text"
                            value={order.location.id}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "location", {
                                ...order.location,
                                id: e.target.value
                            })}
                        />
                        <label>Property ID: </label>
                        <input
                            type="text"
                            value={order.location.property?.id}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "location", {
                                ...order.location,
                                property: {...order.location.property, id: e.target.value}
                            })}
                        />
                        <br/>
                        <label>Expected Duration (s): </label>
                        <input
                            type="number"
                            value={order.expectedDuration}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "expectedDuration", parseFloat(e.target.value))}
                        />
                        <label>Urgent: </label>
                        <input
                            type="checkbox"
                            checked={order.urgent || false}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "urgent", e.target.checked)}
                        />
                        <label>Service Request: </label>
                        <input
                            type="checkbox"
                            checked={order.raisedByResident || false}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "raisedByResident", e.target.checked)}
                        />
                        <label>Raised At: </label>
                        <input
                            type="datetime-local"
                            value={formatDateForInput(order.raisedAt)}
                            onChange={(e) => handleWorkOrderChange(orderIndex, "raisedAt", e.target.value)}
                        />
                        <table>
                            <thead>
                            <tr>
                                <th>Requires Skill</th>
                                <th>Requires Certification</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {order.requiredSkills.map((skill, skillIndex) => (
                                <tr key={skillIndex}>
                                    <td>
                                        <select
                                            onChange={(e) => handleRequiredSkillChange(orderIndex, skillIndex, "skill", {name: e.target.value})}>
                                            {skills.map((s) => (
                                                <option key={s} value={s} selected={s === skill.skill.name}>{s}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={skill.requiresCertification}
                                            onChange={(e) => handleRequiredSkillChange(orderIndex, skillIndex, "requiresCertification", e.target.checked)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => deleteRequiredSkill(orderIndex, skillIndex)}>Delete Skill</button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <button onClick={() => addRequiredSkill(orderIndex)}>Add Required Skill</button>
                        <button onClick={() => deleteWorkOrder(orderIndex)}>Delete Work Order</button>
                    </div>
                ))}
                <button onClick={addWorkOrder}>Add Work Order</button>
            </div>
            <button className="smartAssign" onClick={handleSubmit}>Magic Assign</button>
        </div>
    );
};

export default WoaEditor;
